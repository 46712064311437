import {
  Autocomplete, MenuItem, Button,
  TextField, useMediaQuery, Chip, IconButton,
} from '@mui/material';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { greyboxApiActions } from '../../redux/api';
import { setCurrentClinic } from '../../redux/clinic';

const parseData = (data, companies) => {
  if (!data.results) return [];

  return (data.results.map((item) => ({
    id: item.uuid,
    key: `${item.id}-${item.company[0]}`,
    label: `${item.firstName} ${item.lastName}`,
    company: companies.find((company) => company.id === item.company[0])?.name,
    companyId: item.company[0],
  })));
};

const SearchAccountFieldDeskTop = ({ handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account, company } = greyboxApiActions;
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [searchTerm, setSearchTerm] = useState('');
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const companies = company.list({});

  const getInputWidth = () => {
    if (isMobile) {
      return '100%';
    }

    return isFocused ? '450px' : '250px';
  };

  const { data = {}, isFetching } = account.list(
    {
      page_size: 10,
      search: searchTerm,
      acc_type: 'P',
    },
    {
      skip: access === 'P' || !query,
    },
  );

  const handleClick = (accountId, companyId) => {
    setOpen(false);
    setIsFocused(false);
    handleClose();
    setQuery('');
    setSearchTerm('');

    if (companyId && companyId !== clinic.id) {
      dispatch(setCurrentClinic(companies.data.find((item) => item.id === companyId)));
    }
    navigate(`/patient-profile/${accountId}/overview`);
  };

  const handleInputChange = (event, newInputValue) => {
    setQuery(newInputValue);
  };

  useEffect(() => {
    if (query) {
      setOpen(true);
    }
  }, [query]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(query), 350);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    access === 'PT' && (
      <Autocomplete
        open={open}
        sx={{ mt: 1, mx: isMobile ? 1 : 'auto', width: isMobile ? 'auto' : 'fit-content' }}
        onClose={() => {
          setOpen(false);
        }}
        options={parseData(data, companies.data)}
        fullWidth={isMobile}
        filterOptions={(x) => x}
        blurOnSelect
        value={query}
        onInputChange={handleInputChange}
        openText={t('Open')}
        clearText={t('Clear')}
        noOptionsText={t('No patient found')}
        loadingText={`${t('Loading')}...`}
        loading={isFetching}
        renderOption={(props, option) => (
          <MenuItem key={option.key} onClick={() => handleClick(option.id, option.companyId)}>
            {option.label}
            {option.company && (
              <Chip sx={{ ml: 'auto' }} label={option.company} size="small" />
            )}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={t('Search patient')}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                isMobile ? (
                  <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                ) : null
              ),
              sx: {
                borderRadius: '20px',
                width: getInputWidth,
                transition: 'width 0.3s ease-in-out',
              },
            }}
          />
        )}
      />
    )
  );
};

const SearchAccountField = ({ isButton = false, setOpen }) => {
  if (isButton) {
    return (
      <Button sx={{ px: 0 }} onClick={() => setOpen(true)}>
        <SearchIcon />
      </Button>
    );
  }

  return (<SearchAccountFieldDeskTop handleClose={() => setOpen(false)} />);
};

export default SearchAccountField;
