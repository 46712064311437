import {
  Button, 
  Checkbox,
  Paper,
  Table,
  TableBody,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import Contraindications from '../suggestedMedications/contraindications';
import AddMedicationModal from './AddMedicationModal';
import { currentMed } from './currentMedications_styling';
import DeleteMedicationModal from './DeleteMedicationModal';
import EditMedicationModal from './EditMedicationModal';
import IconActions from './IconActions';
import PosologyModes from './posologyModes';
import StopMedicationModal from './StopMedicationModal';
import {
  arraysMatch, getComparator, stableSort,
} from '../../../../helper-functions';
import {
  NextDoseOutlined, PopUp, Sorted,
  Sorting, Taken_no_halo, TimeOutlined, Tooltips, XIcon,
} from '../../../tk-ui';
import { formikToText } from './utils';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowY: 'hidden',
  },
  deleteBtn: {
    borderColor: theme.palette.error,
    color: theme.palette.error,
  },
  tableHead: {
    height: '30px',
    padding: 0,
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  tableHeadCells: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    position: 'relative',
    fontWeight: '500',
    padding: '9px',
  },
  tableHeadSelected: {
    color: theme.palette.primary.main,
  },
  tableHeadPointer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: 0,
    cursor: 'pointer',
  },
  tableHeadIcon: {
    position: 'absolute',
    right: '1px',
    top: '9px',
    cursor: 'pointer',
  },
  tableHeadRotate: {
    transform: 'rotate(180deg)',
    transformOrigin: '50% 40%',
    transition: 'all 200ms ease',
  },
  tableHeadRotateBack: {
    transformOrigin: '50% 40%',
    transition: 'all 200ms ease',
  },
  medNameCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // dictates the id selected for sorting i.e. startDate
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const cellSettings = [
    {
      id: 'name',
      align: 'left',
      label: t('Medication'),
    },
    {
      id: 'statusName',
      align: 'left',
      label: t('Titration status'),
    },
    {
      id: 'parsed',
      align: 'left',
      label: t('Dosage'),
    },
    {
      id: 'startDate',
      align: 'left',
      padding: 'default',
      label: t('Start'),
    },
    {
      id: 'endDate',
      align: 'left',
      label: t('End'),
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount === 0 ? false : numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all medications' }}
            color="primary"
          />
        </TableCell>
        {cellSettings.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            className={orderBy === headCell.id ? (
              `${classes.tableHeadCells} ${classes.tableHeadSelected}`
            ) : (
              classes.tableHeadCells
            )}
          >
            {headCell.label}
            <div
              className={classes.tableHeadPointer}
              role="button"
              tabIndex={0}
              onKeyDown={createSortHandler(headCell.id)}
              onClick={createSortHandler(headCell.id)}
            >
              <div className={classes.tableHeadIcon}>
                {orderBy === headCell.id ? (
                  <div className={order === 'desc' ? (
                    classes.tableHeadRotate
                  ) : (
                    classes.tableHeadRotateBack
                  )}
                  >
                    <Sorted />
                  </div>
                ) : (
                  <Sorting />
                )}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

// toolbar for different action btn ( add, edit cease )
const MedicationActions = (props) => {
  const {
    selectedMeds,
    setSelectedMeds,
    isHistory,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editSelection, setEditSelection] = useState(null);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { edit } = useSelector((state) => state.permissions);
  const { access } = useSelector((state) => state.user);

  // Enable add/edit/stop buttons only for Doctors and Nurse practitioner
  const enabled = access === 'PT';

  const handleEditClick = () => {
    setEditSelection(selectedMeds[0].uuid);
    setAddModalOpen(true);
  }

  const oneIsNotInPlan = selectedMeds.some((med) => med.isNotSuggested);
  return (
    <Toolbar disableGutters variant="dense" data-cy="toolbar">
      <Stack spacing={1} direction="row" sx={{ ml: 1 }}>
        {!isHistory && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              size="small"
              disabled={!enabled || !edit}
              onClick={() => setAddModalOpen(true)}
              startIcon={<AddIcon />}
            >
              {t('Add')}
            </Button>
            <AddMedicationModal
              open={addModalOpen}
              selectedId={editSelection}
              handleClose={() => {
                setAddModalOpen(false);
                setEditSelection(null);
                setSelectedMeds([]);
              }}
            />
            <Button
              variant="outlined"
              disabled={!enabled || selectedMeds.length !== 1 || oneIsNotInPlan || !edit}
              className={classes.button}
              size="small"
              onClick={() => handleEditModalOpen(false)}
              startIcon={<ArrowForwardIcon />}
            >
              {t('Next Step')}
            </Button>
            <Button
              variant="outlined"
              disabled={!enabled || selectedMeds.length !== 1 || !edit}
              className={classes.button}
              size="small"
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              {t('Edit')}
            </Button>
            <Button
              variant="outlined"
              disabled={!enabled || selectedMeds.length === 0 || !edit}
              onClick={() => setStopModalOpen(true)}
              className={classes.button}
              startIcon={<NotInterestedIcon />}
              size="small"
            >
              {t('Stop')}
            </Button>
            {stopModalOpen && (
              <StopMedicationModal
                open={stopModalOpen}
                selectedMeds={selectedMeds}
                handleClose={() => {
                  setStopModalOpen(false);
                  setSelectedMeds([]);
                }}
              />
            )}
          </>
        )}
        <Button
          disabled={!enabled || selectedMeds.length === 0}
          className={`${classes.button} ${classes.deleteBtn}`}
          variant="outlined"
          size="small"
          startIcon={<DeleteOutlineIcon />}
          onClick={() => setDeleteModalOpen(true)}
        >
          {t('Delete')}
        </Button>
        <DeleteMedicationModal
          open={deleteModalOpen}
          handleClose={() => {
            setDeleteModalOpen(false);
            setSelectedMeds([]);
          }}
          selectedMedications={selectedMeds}
        />
      </Stack>
    </Toolbar>
  );
};
const MedicationTable = (props) => {
  const {
    meds,
    isHistory,
    selected,
    setSelected,
  } = props;

  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { uuid } = useParams();
  const [hovered, setHovered] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(isHistory ? 'endDate' : 'startDate');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMeds, setSelectedMeds] = useState([]);
  const lang = i18n.resolvedLanguage;
  const { titrationStatus } = greyboxApiActions;
  const { data = [] } = titrationStatus.list({ patient: uuid });
  const { access } = useSelector((state) => state.user);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = meds.map((n) => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getSelectedMeds = (selectedItems) => meds.filter((el) => selectedItems.includes(el.uuid));

  useEffect(() => {
    setSelectedMeds(getSelectedMeds(selected));
  }, [selected]);

  useEffect(() => {
    setOrderBy(isHistory ? 'endDate' : 'startDate');
  }, [isHistory]);

  const handleClick = (uuid) => {
    const selectedIndex = selected.indexOf(uuid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // Fn to select item by ROW, and can select only one
  const handleHover = (event, uuid) => setHovered([uuid]);
  const isHovered = (name) => hovered.length > 0 && hovered.indexOf(name) !== -1;

  // check role type enable graphs and contraindications to HCPs
  const enabled = access === 'PT';
  const writeFeatures = access === 'PT';

  moment.updateLocale('en', {
    relativeTime: {
      past: '%s late',
    },
  });

  moment.updateLocale('fr', {
    relativeTime: {
      past: '%s de retard',
    },
  });

  return (
    <Paper style={currentMed.paperStyle} data-cy="paper">
      <MedicationActions
        isHistory={isHistory}
        setModalOpen={setModalOpen}
        hovered={hovered}
        selectedMeds={selectedMeds}
        setSelectedMeds={setSelectedMeds}
      />
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          style={currentMed.tableTop}
        >
          <EnhancedTableHead
            numSelected={selectedMeds.length}
            order={order}
            isHistory={isHistory}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={meds.length}
          />
          <TableBody>
            {stableSort(meds, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = Boolean(selectedMeds.find((el) => el.uuid === row.uuid));
                const isItemHovered = isHovered(row.uuid);
                const labelId = `enhanced-table-checkbox-${index}`;
                const unitLang = lang === 'fr' ? row.unitFr : row.unit;
  
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.uuid}
                    onMouseEnter={(e) => handleHover(e, row.uuid)}
                    selected={isItemSelected}
                    data-cy="tableRow"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={() => handleClick(row.uuid)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                        data-cy="currentMedCheckbox"
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      onClick={null}
                      scope="row"
                      data-cy="cellWrapper"
                      style={currentMed.cellsFlex}
                    >
                      <div className={classes.medNameCell}>
                        <div
                          style={isHistory ? currentMed.rowSpaceHisto : currentMed.rowSpace}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleClick(row.uuid)}
                        >
                          {' '}
                          {row.name}
                          {' '}
                          {row.isNotSuggested && !isHistory && (
                            <Tooltips
                              text={t('Non-suggested medication')}
                              data-cy="not_Taken_no_halo"
                              delayTime={200}
                            >
                              <span style={currentMed.isSuggested}>
                                <Taken_no_halo data-cy="medNotTakenIcon" fills="#f49342" />
                              </span>
                            </Tooltips>
                          )}
                          {!row.isNotSuggested && !isHistory && (
                            <Tooltips
                              text={t('Suggested medication')}
                              data-cy="not_Taken_no_halo"
                              delayTime={200}
                            >
                              <span style={currentMed.isSuggested}>
                                <Taken_no_halo data-cy="medTakenIcon" fills="#50b83c" />
                              </span>
                            </Tooltips>
                          )}
                          {row.lastActiveDay && (
                            <Tooltips
                              text={t('Last day')}
                              delayTime={200}
                              customPos={{ transform: 'translateY(-8px)' }}
                            >
                              <span style={currentMed.isLast}>
                                <TimeOutlined data-cy="lastActiveDayIcon" fills="#dea533" />
                              </span>
                            </Tooltips>
                          )}
                          {row.nextDoseDate && row.nextDose && !isHistory && (
                            <Tooltips
                              text={(
                                <>
                                  <div style={{ paddingBottom: '2px' }}>
                                    {`${t('Next Step')}: + ${row.nextDose} ${row.unit}`}
                                  </div>
                                  <div>
                                    {
                                      moment(row.nextDoseDate)
                                        .locale(i18n.resolvedLanguage)
                                        .fromNow()
                                    }
                                  </div>
                                </>
                              )}
                              data-cy="NextDoseOutlined"
                              delayTime={200}
                            >
                              <span
                                style={
                                  row.lastActiveDay ? (
                                    currentMed.isNextAndLast
                                  ) : (
                                    currentMed.isNext
                                  )
                                }
                              >
                                <NextDoseOutlined
                                  data-cy="nextIcon"
                                  fills={row.nextDoseTime > 1 ? 'info' : 'warning'}
                                />
                              </span>
                            </Tooltips>
                          )}
                          {row.ingredient.map((molecule) => (
                            <div style={currentMed.rowsIngredients} key={molecule.drugUuid}>
                              {lang === 'en' ? molecule.molecule : molecule.moleculeFr}
                              {' '}
                              {molecule.dosageUnit === 'nan' ? (
                                <span style={{ textTransform: 'lowercase', fontWeight: '400' }}>
                                  (
                                  {lang === 'en' ? molecule.strength : molecule.strengthFr}
                                  )
                                </span>
                              ) : (
                                <span style={{ textTransform: 'lowercase', fontWeight: '400' }}>
                                  (
                                  {lang === 'en' ? molecule.strength : molecule.strengthFr}
                                  {' '}
                                  /
                                  {' '}
                                  {lang === 'en' ? molecule.dosageUnit : molecule.dosageUnitFr}
                                  )
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={currentMed.cellPadding}>
                      {row.statusName ? (
                        <div style={{ position: 'relative', cursor: 'auto' }} data-cy="graphs">
                          <span
                            style={{ ...currentMed.titrationBadge, background: row.statusColor }}
                          >
                            {t(row.statusName)}
                            {' '}
                          </span>
                        </div>
                      ) : (
                        <div>
                          {!isHistory ? (
                            <span style={currentMed.titrationNone}>
                              {t('No available plan')}
                            </span>
                          ) : (
                            <span>---</span>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell style={currentMed.cellStyle} onClick={() => handleClick(row.uuid)}>
                      {row.parsed ? formikToText(row.parsed) : '---'}
                    </TableCell>
                    <TableCell style={currentMed.cellStyle} onClick={() => handleClick(row.uuid)}>
                      {row.startDate}
                    </TableCell>
                    <TableCell style={currentMed.dateCell} onClick={() => handleClick(row.uuid)}>
                      {row.lastActiveDay ? (
                        <span
                          style={{ fontWeight: '500', letterSpacing: '-0.15px' }}
                          data-cy="isLast"
                        >
                          {row.endDate}
                        </span>
                      ) : (
                        <span data-cy="isNotLast">{row.endDate}</span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicationTable;
