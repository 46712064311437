import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DialogTitle from '../../DialogTitle';
import DialogQuestionnaire from './filler/DialogQuestionnaire';

/**
 *  Dialog that prompts the user to select a questionnaire to fill out.
 *  After selecting a questionnaire, the corresponding questions are displayed.
 */
const QuestionnaireSelector = (props) => {
  const { t } = useTranslation();
  const { questionnaires } = useSelector((state) => state.clinic);
  const [selected, setSelected] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const { patientUuid, open, handleClose } = props;

  useEffect(() => {
    if (open) {
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={isStarted ? 'lg' : 'sm'}
    >
      <DialogTitle onClose={handleClose}>
        {isStarted ? '' : t('Questionnaire selection')}
      </DialogTitle>
      {!isStarted ? (
        <>
          <DialogContent dividers sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('Questionnaire')}</InputLabel>
              <Select onChange={(e) => setSelected(e.target.value)} label="Questionnaire" value={selected}>
                {questionnaires.map((q) => (
                  <MenuItem key={q.id} value={q.id}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsStarted(true)}>
              {t('Start questionnaire')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogQuestionnaire
          patientUuid={patientUuid}
          open={open}
          onComplete={() => handleClose}
          handleClose={() => handleClose}
          questionnaireId={selected}
        />
      )}
    </Dialog>
  );
};


export default QuestionnaireSelector;
