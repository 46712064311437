import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import App from './App';
import { AppInsightsContextProvider } from './contexts/AppInsightsContext';
import './i18n';
import store from './redux/store';
import { msalInstance } from './authConfig';



const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <AppInsightsContextProvider>
        <Router>
          <App instance={msalInstance} />
        </Router>
      </AppInsightsContextProvider>
    </DndProvider>
  </Provider>,
);
