import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete, Select, Box, Chip,
  Button, Dialog, DialogActions, DialogContent, MenuItem, TextField, FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import FormSelect from '../form-inputs/FormSelect';
import FormTextField from '../form-inputs/FormTextField';
import Language from '../form-inputs/Language';
import PhoneNumber from '../form-inputs/PhoneNumber';
import Row from '../Row';

const CareTeamInvitation = (props) => {
  const {
    open, handleClose, careTeamId = null,
  } = props;
  const { t, i18n } = useTranslation();
  const { invitation, careTeam, roles } = greyboxApiActions;
  const [redirect, setRedirect] = React.useState(false);
  const [sendInvitation] = invitation.add();
  const [hasPatient, setHasPatient] = React.useState(false);
  const { clinic } = useSelector((state) => state.clinic);
  const roleSelector = roles.list({ acc_type__in: 'PT,CP' });
  const { data = [], isFetching } = careTeam.list({
    clinic: clinic.id,
    'managing-organization:Organization.identifier': clinic.id,
    status: 'active',
  });

  const PREFIXES = {
    CPR: 'RelatedPerson',
    CPP: 'Practitioner',
  };

  const TYPES_MAPPING = {
    CPP: 'PT',
    CPR: 'CP',
  };

  const TYPES = [{
    id: 'CPR',
    label: t('Related Person'),
  },
  {
    id: 'CPP',
    label: t('Practitioner'),
  }];

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      roles: '',
      language: 'fr',
      careTeam: careTeamId || '',
      type: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      if (values.email === '' && values.phoneNumber === '') {
        return {
          email: t('Must provide either email or phone number'),
          phoneNumber: t('Must provide either email or phone number'),
        };
      }
      return null;
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      type: Yup.string().required(t('Required')),
      email: Yup.string().email(t('Invalid email')),
      phoneNumber: Yup.string(),
      language: Yup.string().required(t('Required')),
      roles: Yup.array().required(t('Required')),
      careTeam: Yup.string().required(t('Required')),
    }),

    onSubmit: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber.length < 7 ? null : values.phoneNumber,
        language: values.language,
        clinic: clinic.id,
        type: values.type,
        invitationFormFields: {
          firstName: values.firstName,
          lastName: values.lastName,
          careteam: {
            id: values.careTeam,
            participant: [{
              member: {
                reference: `${PREFIXES[values.type]}/#pk`,
              },
              role: values.roles.map(
                (role) => ({
                  coding: [{
                    code: role,
                  }],
                }),
              ),
            }],
          },
          email: values.email,
          phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber,
          language: values.language,
        },
      };

      sendInvitation({ body, feedback: { success: t('Invitation sent') } }).then(() => {
        formik.setSubmitting(false);
        formik.resetForm();

        if (redirect) {
          handleClose(Boolean(careTeamId));
        }
      });
    },
  });

  useEffect(() => {
    if (careTeamId && data.entry) {
      const current = data.entry.find((entry) => entry.resource.id === careTeamId);
      if (current && current.resource.subject) {
        setHasPatient(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (hasPatient) {
      formik.setFieldValue('type', 'CPR');
    } else {
      formik.setFieldValue('type', 'CPP');
    }
  }, [hasPatient]);

  useEffect(() => {
    if (formik.values.type === 'CPR') {
      formik.setFieldValue('roles', ['CTP']);
    }
  }, [formik.values.type]);

  if (isFetching) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Register a new care team participant')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <FormTextField formik={formik} name="firstName" label={t('First name')} required />
            <FormTextField formik={formik} name="lastName" label={t('Last name')} required />
          </Row>
          <Row>
            <FormTextField formik={formik} name="email" label={t('Email')} />
            <PhoneNumber formik={formik} name="phoneNumber" />
          </Row>
          <Row>
            <Language formik={formik} name="language" />
            <FormSelect
              formik={formik}
              name="type"
              label={t('Type')}
              disabled={!hasPatient}
              required
            >
              {TYPES.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.label}
                </MenuItem>
              ))}
            </FormSelect>
          </Row>
          <Row>
            {roleSelector.data
            && (
              <FormSelect
                formik={formik}
                name="roles"
                disabled={formik.values.type === 'CPR'}
                label={t('Role')}
                onChange={(e) => formik.setFieldValue('roles', [e.target.value])}
              >
                {roleSelector.data
                  .filter((role) => role.acc_type === TYPES_MAPPING[formik.values.type])
                  .map((role) => (
                    <MenuItem key={role.uuid} value={role.role_type}>
                      {role.role_name}
                    </MenuItem>
                  ))}
              </FormSelect>
            )}
            <Autocomplete
              id="careTeam"
              name="careTeam"
              options={data.entry}
              sx={{ mx:1, display: 'inline-flex' }}
              defaultValue={careTeamId !== null ? (
                data.entry.find((entry) => entry.resource.id === careTeamId)
              ) : (
                null
              )}
              disabled={careTeamId !== null}
              getOptionLabel={(option) => option.resource.name}
              onChange={(event, value) => {
                formik.setFieldValue('careTeam', value.resource.id);
                setHasPatient(
                  Boolean(data.entry
                    .find((entry) => entry.resource.id === value.resource.id).resource.subject),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Care team')}
                  required
                  error={formik.touched.careTeam && Boolean(formik.errors.careTeam)}
                  helperText={formik.touched.careTeam && formik.errors.careTeam}
                />
              )}
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ ml: 'auto' }}
            loading={formik.isSubmitting}
          >
            {t('Send and add another')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setRedirect(true)}
            sx={{ ml: 'auto' }}
            loading={formik.isSubmitting}
          >
            {t('Send')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CareTeamInvitation;
