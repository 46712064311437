import {
  Autocomplete, MenuItem, Box, TextField, useMediaQuery, Chip, IconButton, Typography,
} from '@mui/material';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import { Medication } from '../../../types';


type Props = {
  setSelected: (medication: Medication) => void;
};

const SearchMedicationField = ({ setSelected }: Props) => {
  const { t } = useTranslation();
  const { medications } = greyboxApiActions;
  const [searchTerm, setSearchTerm] = useState('');
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { data = {}, isFetching } = medications.list(
    {
      page_size: 10,
      search: searchTerm,
    },
    {
      skip: !searchTerm || !isFocused,
    },
  );

  const options: Medication[] = data.results || [];

  const handleInputChange = ( _: any , newInputValue: string) => {
    setQuery(newInputValue);
  };

  const handleSelect = (item: Medication | null | string) => {
    if (!item || typeof item === 'string') {
      return;
    }

    setSelected(item);
    setQuery('');
    setSearchTerm('');
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(query), 350);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <Autocomplete
      options={options}
      fullWidth
      autoHighlight
      filterOptions={(x) => x}
      blurOnSelect
      freeSolo
      onInputChange={handleInputChange}
      getOptionLabel={(option: string | Medication) => (
        typeof option === 'string' ? option : option.brand_name
      )}
      openText={t('Open')}
      clearText={t('Clear')}
      noOptionsText=""
      onChange={(event, value) => handleSelect(value)}
      loadingText={`${t('Loading')}...`}
      loading={isFetching}
      renderOption={(props, option) => (
        <MenuItem
          key={option.uuid}
          {...props}
        >
          <div>
            <div>
              <Typography>{option.brand_name}</Typography>
            </div>
            <Box display="flex" flexWrap="wrap">
              {option.active_ingredient.map((ingredient) => (
                <Typography key={`${option.uuid}-${ingredient.uuid}`} variant="caption">
                  {ingredient.ingredient}
                  {' '}
                  {ingredient.strength}
                  {' '}
                  {ingredient.strength_unit}
                  {' '}
                </Typography>
              ))}
            </Box>
          </div>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          size="small"
          placeholder={t('Search medication')}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
    />
  );
};

export default SearchMedicationField;
