import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import 'moment/locale/fr';
import React, { useState } from 'react';
import SearchMedication from '../SearchMedication';
import MedicationDetails from './MedicationDetails';
import DialogTitle from '../../../DialogTitle';
import { Medication, MedicationHistoryType } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';

type Props = {
  open: boolean;
  selectedId?: string;
  handleClose: () => void;
};

const AddMedicationModal = ({ open, handleClose, selectedId }: Props) => {
  const [selected, setSelected] = useState<Medication | null>(null);
  const [editSelection, setEditSelection] = useState<MedicationHistoryType | null>(null);
  const { t } = useTranslation();
  const handleQuit = () => {
    setSelected(null);
    setEditSelection(null);
    handleClose();
  };
  const { medication } = greyboxApiActions;

  const { data, isFetching } = medication.get(selectedId, { skip: !selectedId });

  if (data && !editSelection) {
    setEditSelection(data);
  }

  if (isFetching) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleQuit}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle onClose={handleQuit} />
        {!selected && !selectedId ? (
          <>
            <DialogContent>
              <SearchMedication setSelected={setSelected} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleQuit}>{t('Cancel')}</Button>
            </DialogActions>
          </>
        ) : (
          <MedicationDetails
            searchedMed={selected}
            editSelection={editSelection} 
            handleQuit={handleQuit} 
          />
        )}
    </Dialog>
  );
};

export default AddMedicationModal;
