import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Box, MenuItem,
  TextField, FormLabel,
} from '@mui/material';
import Row from '../../../Row';
import { periodOptions, maxDosePeriodType } from './utils';
import { FormSelect, FormTextField, TimePicker } from '../../../form-inputs';


const MaxDosePerPeriod = ({ onChange } : {onChange: (values: maxDosePeriodType) => void}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      maxDose: null,
      maxDoseUnit: null,
      period: null,
      periodUnit: null,
    },
    enableReinitialize: true,
    validate: (values) => {
      if (values.maxDose && !values.maxDoseUnit) {
        return { maxDoseUnit: 'Required' };
      }

      if (values.period && !values.periodUnit) {
        return { periodUnit: 'Required' };
      }

      return {};
    },
    validationSchema: yup.object().shape({
      maxDose: yup.number().positive().nullable(),
      maxDoseUnit: yup.string().nullable(),
      period: yup.number().nullable(),
      periodUnit: yup.string().nullable(),
    }),
    onSubmit: (values) => {
      // do nothing
    },
  });

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  return (
    <Box
      sx={{ p: 1 }}
      border="1px solid"
      borderColor="gray.200"
      borderRadius={2}
      mt={1}
    >
      <FormLabel>{t('Max dose per period')}</FormLabel>
      <Row>
        <FormTextField formik={formik} name="maxDose" label={t('Max dose')} type="number" />
        <FormTextField formik={formik} name="maxDoseUnit" label={t('Dose unit')} type='text' />
        <FormTextField formik={formik} name="period" label={t('Period value')} type="number" />
        <FormSelect formik={formik} name="periodUnit" label={t('Period unit')}>
          {Object.keys(periodOptions).map((key) => (
            <MenuItem key={key} value={key}>
              {t(periodOptions[key])}
            </MenuItem>
          ))}
        </FormSelect>
      </Row>
    </Box>
  );
};

export default MaxDosePerPeriod;
