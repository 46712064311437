import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import i18n from '../../i18n';
import FormTextField from './FormTextField';

export const hinNumberSchema = Yup
  .string()
  .nullable()
  .matches(/^[A-Za-z]{4}(\s?\d{4}){2}$/,
    `${i18n.t('Please make sure to follow the format of 4 letters followed by 8 numbers')}. ${i18n.t('For example')}, ABCD 1234 5678`);



export const formatHinWithSpaces = (hin) => {
  if (hin) {
    return hin.replace(/\s+/g, '').replace(/(\w{4})/g, '$1 ').trim();
  }
  return hin;
};

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

export const Hin = (props: PropsType) => {
  const { t } = useTranslation();

  return (
    <FormTextField
      {...props}
      name="hin_number"
      label={t('Health Insurance Number')}
    />
  );
};
