import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  TableBody, TableCell, TableHead, TableRow, Tooltip, Button, Box, IconButton,
  Checkbox,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Delete from '@mui/icons-material/Delete';
import TableWrapper from '../../TableWrapper';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireAnswers } from '../../../types';
import PrintableLogbook from './PrintableLogBook';

type LogBookTableProps = {
  questionnaireId: number;
  patientUuid: string;
  setOpen: (open: boolean) => void;
  setPreviousAnswers: (previousAnswers: any) => void;
};

type Data = {
  count: number;
  results: QuestionnaireAnswers[];
}

type QuestionnaireAnswer = {
  data: Data;
  isFetching: boolean;
}

const getQuestionText = (question: any) => {
  const key = question.type_of_q.toLowerCase();
  return question[`${key}_question`][`${key}_question_text`];
};

const LogBookTable = ({ questionnaireId, patientUuid, setOpen, setPreviousAnswers }: LogBookTableProps) => {
  const { t } = useTranslation();
  const { edit } = useSelector((state) => state.permissions);
  const clinic = useSelector((state: any) => state.clinic);
  const { questionnaireAnswers, questionnaire, account } = greyboxApiActions;
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment());
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteAnswers] = questionnaireAnswers.delete();
  const [selected, setSelected] = useState([]);
  const printRef = useRef();
  const accountSelector = account.get(patientUuid);
  const questionnaireSelector = questionnaire.list({ id: questionnaireId, clinic: clinic.id });
  const { data, isFetching }: QuestionnaireAnswer = questionnaireAnswers.list(
    {
      patient: patientUuid,
      questionnaire: questionnaireId,
      page_size: rowsPerPage,
      page: page + 1,
      answer_timestamp__range: `${startDate.format('YYYY-MM-DD')}T00:00:00,${endDate.format('YYYY-MM-DD')}T23:59:59`,
      clinic: clinic.id,
    },
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: questionnaireSelector.data?.name,
  });

  const handleDelete = async () => {
    Promise.all(selected.map((id) => deleteAnswers({ id })));
    setSelected([]);
  };

  const findAnswer = (answers: any[], questionId: number) => {
    const ans = answers.find((answer) => answer.base_question === questionId);

    if (ans.answer === true) {
      return t('Yes');
    } if (ans.answer === false) {
      return t('No');
    }

    return ans.answer.toString();
  };

  const handleSelection = (id: number) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const columns = useMemo(() => (
    questionnaireSelector.data?.questions.map((question, index) => ({
      id: question.id,
      label: `${t('Question')} ${index + 1}`,
      tooltip: getQuestionText(question),
      type: question.type_of_q,
    })) || []
  ), [questionnaireSelector.data]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          disabled={!edit}
          data-cy="add-answer"
          onClick={() => setOpen(true)}
          sx={{ mx: 1 }}
          size="small"
        >
          {t('Add')}
        </Button>
        <Button
          startIcon={<PrintIcon />}
          variant="outlined"
          disabled={!edit}
          data-cy="add-answer"
          onClick={() => handlePrint()}
          sx={{ mr: 1 }}
          size="small"
        >
          {t('Print')}
        </Button>
        {selected.length > 0 && (
          <Button
            startIcon={<Delete />}
            variant="outlined"
            onClick={handleDelete}
            disabled={!edit}
            sx={{ mr: 1 }}
            size="small"
          >
            {t('Delete')}
          </Button>
        )}
        <Box sx={{ ml: 'auto', mr: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={t('Start Date')}
              sx={{ mr: 1 }}
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              label={t('End Date')}
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <TableWrapper
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data?.count}
        isFetching={isFetching || questionnaireSelector.isFetching}
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell sx={{ minWidth: 190 }}>
              {t('Date')}
            </TableCell>
            {columns.map((column) => (
              <TableCell
                align="center"
                key={column.id}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  {column.label}
                  <Tooltip title={column.tooltip} sx={{ ml: 1, cursor: 'help' }}>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
            ))}
            {questionnaireSelector.data?.has_score && (
              <TableCell>
                {t('Score')}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.results.map((row) => (
            <TableRow key={row.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected.includes(row.id)}
                  onChange={() => handleSelection(row.id)}
                />
              </TableCell>
              <TableCell>
                {format(row.answer_timestamp, 'PPpp')}
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id} align="center">
                  {findAnswer(row.answers, column.id)}
                </TableCell>
              ))}
              {questionnaireSelector.data?.has_score && (
                <TableCell align="right">
                  {row.score?.value}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </TableWrapper>
      <Box sx={{ display: 'none' }}>
        <PrintableLogbook
          ref={printRef}
          answers={data?.results}
          patient={accountSelector.data}
          questionnaire={questionnaireSelector.data}
        />
      </Box>
    </>
  );
};

export default LogBookTable;
