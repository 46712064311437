import { LoadingButton } from '@mui/lab';
import {
  Button, Card, CardActions, CardContent, CardHeader, FormControlLabel,
  Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Link,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import FormTextField from '../components/form-inputs/FormTextField';
import { Hin, hinNumberSchema } from '../components/form-inputs/Hin';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import { ZipCode, zipCodeSchema } from '../components/form-inputs/ZipCode';
import BirthDate, { formatBirthDateForPost } from '../components/form-inputs/BirthDate';
import EthnicGroup from '../components/form-inputs/EthnicGroup';
import Sex from '../components/form-inputs/Sex';
import Language from '../components/form-inputs/Language';
import Layout from '../components/Layout';
import { greyboxApiActions } from '../redux/api';
import TermOfUseDialog from '../components/TermOfUseDialog';
import { setTempAuthorization } from '../redux/authorization';
import Row from '../components/Row';

const CompleteRegistration = () => {
  const { t, i18n } = useTranslation();
  const { patientRegistration } = greyboxApiActions;
  const [createAccount, { isLoading }] = patientRegistration.add();
  const [open, setOpen] = React.useState(false);
  const { search } = useLocation();
  const code = new URLSearchParams(search).get('code');
  const [emailOpen, setEmailOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthDate: null,
      phoneNumber: '',
      zipCode: '',
      hin_number: '',
      gender: 'U',
      language: 'fr',
      newPassWord: '',
      passwordConfirm: '',
      ethnic_group: 'U',
      mailOptIn: false,
      acceptedTerms: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      birthDate: Yup.date().nullable(),
      email: Yup.string().email('Invalid email address'),
      zipCode: zipCodeSchema,
      phone_number: Yup.string(),
      hin_number: hinNumberSchema,
      newPassWord: Yup.string().required(t('Required')).min(8, t('Password must be at least 8 characters')),
      passwordConfirm: Yup.string().oneOf([Yup.ref('newPassWord'), null], t('Passwords must match')),
      acceptedTerms: Yup.boolean().oneOf([true], t('You must accept the terms of use')),
      mailOptIn: Yup.boolean(),
    }),
    onSubmit: (values) => {
      const body = {
        registration_codes: [code],
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          mobile: values.phone_number,
          language: values.language,
          hin: {
            hin_number: values.hin_number,
          },
          birthDate: formatBirthDateForPost(values.birthDate),
          zipCode: values.zipCode,
          mailOptIn: values.mailOptIn,
          acceptedTerms: values.acceptedTerms,
          password: values.newPassWord,
        },
      };
      createAccount({ body }).then((res) => {
        if (res.error) {
          if (res.error.data.user[0] === 'email already in use') {
            formik.setFieldError('email', t('Email already in use'));
            setEmailOpen(true);
          }
          // Do nothing
        } else {
          dispatch(setTempAuthorization({ token: `Token ${res.data.access_token}` }));
          i18n.changeLanguage(values.language);
          moment.locale(values.language);
          navigate(`/patient-profile/${res.data.id}`);
        }
      });
    },
    enableReinitialize: true,
  });

  return (
    <Layout>
      <Card>
        <CardHeader title={t('Activate Your Account')} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Row>
              <FormTextField formik={formik} name="firstName" required label={t('First Name')} />
              <FormTextField formik={formik} name="lastName" required label={t('Last Name')} />
            </Row>
            <Row>
              <FormTextField formik={formik} name="email" label={t('Email')} />
              <PhoneNumber formik={formik} name="phone_number" />
            </Row>
            <Row>
              <Language formik={formik} />
              <BirthDate formik={formik} />
            </Row>
            <Row>
              <ZipCode formik={formik} />
              <Sex formik={formik} />
            </Row>
            <Row>
              <EthnicGroup formik={formik} />
              <Hin formik={formik} />
            </Row>
            <Row>
              <FormTextField required formik={formik} type="password" name="newPassWord" label={t('Password')} />
              <FormTextField required formik={formik} type="password" name="passwordConfirm" label={t('Confirm Password')} />
            </Row>
            <FormControlLabel
              sx={{ m: 0 }}
              onChange={formik.handleChange}
              control={<Checkbox name="mailOptIn" />}
              label={t('I would like to receive newsletters from Greybox and its partners')}
            />
            <FormControlLabel
              sx={{ m: 0 }}
              onChange={formik.handleChange}
              control={<Checkbox name="acceptedTerms" />}
              label={(
                <Trans i18nKey="I have read and agree to the Terms of Use and Privacy Policy">
                  I have read and agree to the
                  <Link onClick={() => setOpen(true)}>
                    Terms of Use and Privacy Policy
                  </Link>
                </Trans>
              )}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('Activate')}
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
      <TermOfUseDialog open={open} handleClose={() => setOpen(false)} />
      <AlreadyInUseDialog open={emailOpen} handleClose={() => setEmailOpen(false)} />
    </Layout>
  );
};

const AlreadyInUseDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('Email already in use')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${t('This email is already in use')}.`}
          {' '}
          {`${t('Please login or use another email address')}.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('Close')}
        </Button>
        <Button onClick={() => navigate('/')} color="primary">
          {t('Login')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteRegistration;
