import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card, CardActions, CardContent, CardHeader,
  Checkbox, FormControl,
  FormControlLabel,
  Link,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '../components/Layout';
import Row from '../components/Row';
import BirthDate, { formatBirthDateForPost } from '../components/form-inputs/BirthDate';
import EthnicGroup from '../components/form-inputs/EthnicGroup';
import FormTextField from '../components/form-inputs/FormTextField';
import { Hin, formatHinWithSpaces, hinNumberSchema } from '../components/form-inputs/Hin';
import Language from '../components/form-inputs/Language';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import Sex from '../components/form-inputs/Sex';
import { ZipCode, zipCodeSchema } from '../components/form-inputs/ZipCode';
import { greyboxApiActions } from '../redux/api';
import { setIsInvitation } from '../redux/invitation';
import moment from 'moment';

const CompleteRegistration = () => {
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const { accountId, role, invitations } = useSelector((state) => state.user);
  const { data = {}, isLoading } = account.get(accountId);
  const [createAccount] = account.add();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authorization);
  const [errorMessage, setErrorMessage] = useState('');

  // Extract the clinic_name from the invitations array
  const clinicName = invitations && invitations.length > 0 ? invitations[0].clinic_name : null;

  const formik = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email || '',
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      phoneNumber: data.phoneNumber || '',
      zipCode: data.zipCode || '',
      hin_number: formatHinWithSpaces(data.hin?.hin_number),
      gender: data.gender || 'U',
      language: data.language || 'en',
      ethnic_group: data.ethnic_group || 'U',
      newPassWord: '',
      passwordConfirm: '',
      mailOptIn: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      birthDate: Yup.date().nullable(),
      email: Yup.string().email(t('Invalid email address')),
      zipCode: zipCodeSchema,
      phoneNumber: Yup.string(),
      hin_number: hinNumberSchema,
      newPassWord: Yup.string().required(t('Required')).min(8, t('Must be at least 8 characters')),
      passwordConfirm: Yup.string().oneOf([Yup.ref('newPassWord'), null], t('Passwords must match')),
      mailOptIn: Yup.boolean(),
    }),
    onSubmit: (values) => {
      setErrorMessage('');
      const body = {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          mobile: values.phoneNumber.length < 7 ? null : values.phoneNumber,
          language: values.language,
          password: values.newPassWord,
        },
        mailOptIn: values.mailOptIn,
        birthDate: formatBirthDateForPost(values.birthDate),
        accepted_terms_on: new Date().toISOString(),
        zipCode: values.zipCode,
      };
      createAccount({ body: body })
        .unwrap()
        .then((data) => {
          // Invitation is completed
          dispatch(setIsInvitation(false));

          // User logged in via azure password reset and his bearer is no longer valid
          // since the account was deleted then recreated with new password
          if (!token) {
            window.location.reload();
          }

          if (role === 'P') {
            navigate(`/patient-profile/${data.uuid}`);
          } else {
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          const errors = error.data;
          if (errors.error) {
            setErrorMessage(errors.error);
          }
          formik.setErrors(errors);
        });
    },
    enableReinitialize: true,
  });

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardHeader title={t('Activate Your Account')} />
          <CardContent>
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            {clinicName && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {t('You have been invited by {{clinicName}} clinic.', { clinicName })}
              </Alert>
            )}
            <Row>
              <FormTextField formik={formik} name="firstName" required label={t('First Name')} />
              <FormTextField formik={formik} name="lastName" required label={t('Last Name')} />
            </Row>
            <Row>
              <FormTextField formik={formik} name="email" label={t('Email')} />
              <PhoneNumber formik={formik} name="phoneNumber" />
            </Row>
            {role === 'P' && (
              <>
                <Row>
                  <Language formik={formik} />
                  <BirthDate formik={formik} />
                </Row>
                <Row>
                  <ZipCode formik={formik} />
                  <Sex formik={formik} />
                </Row>
                <Row>
                  <EthnicGroup formik={formik} />
                  <Hin formik={formik} />
                </Row>
              </>
            )}
            <Row>
              <FormTextField required formik={formik} type="password" name="newPassWord" label={t('Password')} />
              <FormTextField required formik={formik} type="password" name="passwordConfirm" label={t('Confirm Password')} />
            </Row>
            <FormControlLabel
              sx={{ m: 0 }}
              control={<Checkbox name="mailOptIn" checked={formik.values.mailOptIn} onChange={formik.handleChange} />}
              label={t('I would like to receive newsletters from Greybox and its partners')}
            />
            <FormControl
              component="fieldset"
              sx={{ mt: 2 }}
            >
              <Typography variant="body2" sx={{ mt: 2 }}>
                <Trans i18nKey="By activating your account, you agree to the Terms of Use and Privacy Policy">
                  By activating your account, you agree to the{' '}
                  <Link target='_blank' href='https://greybox.ca/terms'>
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link target='_blank' href='https://greybox.ca/privacy'>
                    Privacy Policy
                  </Link>.
                </Trans>
              </Typography>
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" color="primary">
              {t('Activate')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default CompleteRegistration;
