import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@mui/material';

interface PropsType {
  children: React.ReactNode;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  sx?: any;
  formik: any;
}

const FormSelect: React.FC<PropsType> = ({
  formik,
  name,
  label,
  required = false,
  sx = {},
  children,
  disabled = false,
  multiple = false,
  ...other
}) => {
  const error = Boolean(formik.touched[name] && formik.errors[name]);
  const helperText = formik.touched[name] && formik.errors[name];
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  return (
    <FormControl
      error={error}
      disabled={disabled}
      sx={{
        mx: 1,
        ...sx, // Merge any custom styles passed as props
      }}
    >
      <InputLabel id={`${name}-label`}>{required ? `${label} *` : label}</InputLabel>
      <Select
        id={name}
        labelId={`${name}-label`}
        name={name}
        label={required ? `${label} *` : label} // Add asterisk if required
        value={formik.values[name] || (multiple ? [] : '')}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        multiple={multiple}
        data-cy={name}
        aria-describedby={ariaDescribedBy}
        {...other}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText id={ariaDescribedBy}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelect;
