import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Box, MenuItem,
  TextField, FormLabel,
} from '@mui/material';
import Row from '../../../Row';
import { whenOptions, handlePreset, timingCode, timingFormType } from './utils';
import { FormSelect, FormTextField } from '../../../form-inputs';
import { Timing as TimingType } from '../../../../types';


type TimingProps = {
  formik: any;
};

const Timing = ({ formik }: TimingProps) => {
  const { t } = useTranslation();

  const timingFormik = useFormik({
    initialValues: {
      ...formik.initialValues.timing
    },
    enableReinitialize: true,
    validate: (values) => {
      if (values.frequencyMax && values.frequency > values.frequencyMax) {
        return { frequency: 'Frequency must be less than or equal to frequency max' };
      }

      if ((values.timeOfDay && values.when)) {
        return { when: 'Cannot have both time of day and when' };
      }

      return {};
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      frequency: yup.number().required().positive(),
      period: yup.number().required().positive(),
      periodUnit: yup.string().required(),
      when: yup.array(),
      offset: yup.number().nullable(),
      duration: yup.number().positive().nullable(),
      durationUnit: yup.string().nullable(),
      dayOfWeek: yup.array(),
      timeOfDay: yup.string().nullable(),
      count: yup.number().positive().nullable(),
    }),
    onSubmit: () => {
      // do nothing
    },
  });



  useEffect(() => {
    formik.setFieldValue('timing', timingFormik.values)}
  , [timingFormik.values]);

  return (
    <Box
      sx={{ p: 1 }}
      border="1px solid"
      borderColor="gray.200"
      borderRadius={2}
    >
      <FormLabel>{t('Timing')}</FormLabel>
      <Row>
        <TextField
          select
          sx={{ mx: 1 }}
          label={t('Preset')}
          value={timingFormik.values.code}
          onChange={(e) => handlePreset(e.target.value, timingFormik)}
        >
          {timingCode.map((code) => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </TextField>
        <FormTextField formik={timingFormik} required name="frequency" label={t('Frequency')} type="number" />
        <FormTextField formik={timingFormik} name="frequencyMax" label={t('Frequency Max')} type="number" />
      </Row>
      <Row>
        <FormTextField formik={timingFormik} required name="period" label={t('Period')} type="number" />
        <FormSelect formik={timingFormik} name="periodUnit" label={t('Period Unit')}>
          <MenuItem value="h">{t('Hour')}</MenuItem>
          <MenuItem value="d">{t('Day')}</MenuItem>
          <MenuItem value="wk">{t('Week')}</MenuItem>
          <MenuItem value="mo">{t('Month')}</MenuItem>
        </FormSelect>
      </Row>
      <Row>
        <FormSelect formik={timingFormik} name="when" label={t('When')}>
          {Object.entries(whenOptions).map(([key, val]) => (
            <MenuItem key={key} value={key}>
              {val}
            </MenuItem>
          ))}
        </FormSelect>
        <FormTextField formik={timingFormik} name="offset" label={t('Offset in minutes')} type="number" />
      </Row>
      {timingFormik.values.code === 'CUSTOM' && (
        <>
          <Row>
            <FormTextField formik={timingFormik} name="duration" label={t('Duration')} type="number" />
            <FormSelect formik={timingFormik} name="durationUnit" label={t('Duration Unit')}>
              <MenuItem value="s">{t('Second')}</MenuItem>
              <MenuItem value="m">{t('Minute')}</MenuItem>
              <MenuItem value="h">{t('Hour')}</MenuItem>
              <MenuItem value="d">{t('Day')}</MenuItem>
              <MenuItem value="wk">{t('Week')}</MenuItem>
              <MenuItem value="mo">{t('Month')}</MenuItem>
            </FormSelect>
          </Row>
          <Row>
            <FormSelect formik={timingFormik} name="dayOfWeek" label={t('Day of Week')} multiple>
              <MenuItem value="MON">{t('Monday')}</MenuItem>
              <MenuItem value="TUE">{t('Tuesday')}</MenuItem>
              <MenuItem value="WED">{t('Wednesday')}</MenuItem>
              <MenuItem value="THU">{t('Thursday')}</MenuItem>
              <MenuItem value="FRI">{t('Friday')}</MenuItem>
              <MenuItem value="SAT">{t('Saturday')}</MenuItem>
              <MenuItem value="SUN">{t('Sunday')}</MenuItem>
            </FormSelect>
            <FormTextField
              formik={timingFormik}
              name="timeOfDay"
              label={t('Time of Day')}
              type='time'
            />
            <FormTextField formik={timingFormik} name="count" label={t('Count')} type="number" />
          </Row>
        </>
      )}
    </Box>
  );
};

export default Timing;
