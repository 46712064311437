import { createTheme } from '@mui/material';

// Default Styles for Components
const defaultComponents = {
  MuiInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused ': {
            borderRadius: '3px',
          },
        },
      },
      underline: {
        '&:hover:not($disabled):after': {
          borderBottom: '#babdbf',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      underlineNone: {
        '&:hover': {
          opacity: '0.5',
        },
      },
    },
  },
};

const darkComponents = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: '#282A3A',
        },
      },
    },
  },
};

const lightComponents = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
};

// Typography Styles
const defaultTypography = {
  subheading: {
    fontWeight: 300,
    fontSize: '1rem',
  },
  h1: {
    fontSize: '2.5vw',
    fontWeight: '400',
  },
  h2: {
    fontSize: '1vw',
    fontWeight: '400',
  },
};

// Default Palette
const defaultPalette = {
  primary: {
    main: '#2B5766',
    light: '#d4dde0',
    dark: '#1e3c47',
    text: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FFD555',
    main: '#FFC107',
    dark: '#FFA000',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#5C7A8D', 
    light: '#B0C4D0',
    dark: '#2D4754', 
    contrastText: '#FFFFFF', 
  },
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
  },
  border: {
    main: '#DEDEDE',
  },
  gray: {
    main: '#B8B8B8',
    dark: '#747474',
    text: '#FFFFFF',
  },
  accent: {
    main: '#747474',
  },
  disabled: {
    main: '#d2d6d9',
    text: '#454545',
  },
  separator: {
    main: '#E6E6E6',
  },
};

// Light and Dark Theme Variants
const lightTheme = {
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#454545',
    secondary: '#707070',
  },
  accent: {
    main: '#747474',
  },
  separator: {
    main: '#E6E6E6',
  },
};

const darkTheme = {
  background: {
    default: '#000000',
    paper: '#282A3A',
  },
  text: {
    primary: '#F5F5F5',
    secondary: '#F5F5F5',
  },
  accent: {
    main: '#F5F5F5',
  },
  separator: {
    main: '#454545',
  },
};

// Function to Get Theme Based on Mode
const getTheme = (mode, isDarkMode) => {
  const palette = {
    mode,
    ...defaultPalette,
    ...(mode === 'dark' ? darkTheme : lightTheme),
  };

  const components = {
    ...defaultComponents,
    ...(mode === 'dark' ? darkComponents : lightComponents),
  };

  return createTheme({
    components,
    typography: defaultTypography,
    palette,
  });
};

export default getTheme;
