import {
  Box,
  MenuItem, Paper, TextField, Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import CheckboxSelector from '../CheckboxSelector';
import ParticipantTable from './ParticipantTable';
import SubjectTable from './SubjectTable';
import { CARE_TEAM_CATEGORY } from './utils';

const CareTeamDetails = ({patientUuid = null}) => {
  const { t } = useTranslation();
  const { careTeam } = greyboxApiActions;
  const { id } = useParams();
  const { data, isLoading, refetch } = careTeam.get(id);
  const [showTextField, setShowTextField] = React.useState(false);
  const [updateCareTeam] = careTeam.update();

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      category: data?.category?.map((item) => item.coding[0].code) || [],
      status: data?.status || 'proposed',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      category: Yup.array().of(Yup.string()),
      status: Yup.string(),
    }),
    onSubmit: (values) => {
      updateCareTeam({
        id,
        body: {
          name: values.name,
          category: values.category.map((item) => ({ coding: [{ code: item }] })),
          status: values.status,
        },
      });
    },
  });

  useEffect(() => {
    if (!showTextField && formik.values.name !== formik.initialValues.name) {
      formik.handleSubmit();
    }
  }, [showTextField]);

  const handleSelect = ({ id }) => {
    const { category } = formik.values;
    if (category.includes(id)) {
      formik.setFieldValue('category', formik.values.category.filter((item) => item !== id));
    } else {
      formik.setFieldValue('category', [...formik.values.category, id]);
    }
    formik.handleSubmit();
  };

  if (isLoading) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, width: '100%' }}>
      <>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {showTextField ? (
              <TextField
                name="name"
                autoFocus
                onChange={formik.handleChange}
                sx={{ width: '80%' }}
                value={formik.values.name}
                onBlur={() => setShowTextField(false)}
                inputProps={{
                  sx: {
                    fontSize: (theme) => theme.typography.h4.fontSize,
                    fontWeight: (theme) => theme.typography.h4.fontWeight,
                    py: 0,
                  },
                }}
              />
            ) : (
              <Typography
                variant="h4"
                onClick={() => setShowTextField(true)}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'grey.100',
                  },
                  width: '80%',
                }}
              >
                {formik.values.name}
              </Typography>
            )}
            <TextField
              value={formik.values.status}
              select
              variant="standard"
              onChange={(event) => {
                formik.setFieldValue('status', event.target.value);
                formik.handleSubmit();
              }}
              name="status"
              InputProps={{
                disableUnderline: true,
              }}

            >
              <MenuItem value="active">{t('Active')}</MenuItem>
              <MenuItem value="proposed">{t('Draft')}</MenuItem>
              <MenuItem value="inactive">{t('Disabled')}</MenuItem>
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', m: 1 }}>
            {Object.entries(CARE_TEAM_CATEGORY).map(([key, value]) => (
              <CheckboxSelector
                key={key}
                id={key}
                selected={formik.values.category.includes(key)}
                onChange={handleSelect}
              >
                {{ id: key, label: t(value) }}
              </CheckboxSelector>
            ))}
          </Box>
        </form>
        <Typography variant="h6">
          {t('Participants')}
        </Typography>
        <Typography variant="subtitle1">
          {t('Users who are part of this care team')}
        </Typography>
        <ParticipantTable participants={data.participant || []} refetch={refetch} />
        {!patientUuid && (
          <>
            <Typography variant="h6">
              {t('Patients')}
            </Typography>
            <Typography variant="subtitle1">
              {t('Patients that participants have access to')}
            </Typography>
            <SubjectTable />
          </>
        )}
      </>
    </Paper>
  );
};

export default CareTeamDetails;
