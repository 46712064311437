import DomainIcon from '@mui/icons-material/Domain';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button, Menu, MenuItem, TextField,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { greyboxApiActions } from '../redux/api';
import { setCurrentClinic } from '../redux/clinic';

const ClinicButton = () => {
  const { clinic } = useSelector((state) => state.clinic);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [searchInput, setSearchInput] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { data } = greyboxApiActions.company.list({ });

  useEffect(() => {
    if (data) {
      setSearchResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchInput !== '') {
      setSearchResults(
        data.filter(
          (elem) => elem.name.toLowerCase().includes(searchInput.toLowerCase()),
        ),
      );
    } else {
      setSearchResults(data);
    }
  }, [searchInput]);

  const handleKeyDown = (event) => {
    event.stopPropagation();

    switch (event.key) {
      case 'ArrowDown':
        setSelectedIndex((prevIndex) => (
          prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
        ));
        break;
      case 'ArrowUp':
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        break;
      default:
        break;
    }
  };

  const handleCompanyChange = (event) => {
    const selected = data.find((elem) => elem.id === event.target.value);
    navigate('/');
    dispatch(setCurrentClinic(selected));
    setAnchorEl(null);
    setSearchInput('');
    setSelectedIndex(-1);
  };

  if (!clinic) {
    return null;
  }

  return (
    <Box mx={1}>
      <Button
        onClick={(e) => (anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget))}
        value={clinic.id}
        data-cy="clinic-select"
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
        sx={{
          textTransform: 'none',
          px: isMobile ? 0 : 1,
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {isMobile ? (<DomainIcon />) : clinic.name}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onKeyDown={handleKeyDown}
        onClose={() => {
          setAnchorEl(null);
          setSearchInput('');
        }}
        anchorEl={anchorEl}
        sx={{
          maxHeight: '80vh',
          '& .MuiMenu-paper-root': {
            minWidth: '200px',
          },
        }}
      >
        <Box
          onKeyDown={(e) => e.stopPropagation()}
          sx={{ mr: 1 }}
        >
          <TextField
            autoFocus
            placeholder={t('Search')}
            onKeyDown={handleKeyDown}
            value={searchInput}
            size="small"
            sx={{ ml: 0.5, mr: 1, mb: 0.5 }}
            fullWidth
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        {searchResults && searchResults.map((elem, index) => (
          <MenuItem
            key={elem.id}
            selected={index === selectedIndex && searchInput !== ''}
            value={elem.id}
            onClick={handleCompanyChange}
          >
            {elem.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ClinicButton;
