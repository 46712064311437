import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { greyboxApiActions } from '../../../redux/api';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

const apiToForm = (data) => {
  return {
    id: data.id,
    firstName: data?.name?.given[0],
    lastName: data?.name?.family,
    telecom: data?.telecom,
    relationship: data.relationship ? data.relationship[0].coding[0].code : '',
  };
};

const ContactTab = () => {
  const { uuid } = useParams();
  const { account } = greyboxApiActions;
  const { data, isLoading } = account.get(uuid);
  const { t } = useTranslation();
  const [updateContact] = account.update();

  const formik = useFormik({
    initialValues: {
      contact: [
        ...(data?.contact?.map(apiToForm) || [])
      ]
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {contact: []}
      values.contact.forEach((item, index) => {
        body.contact.push({
          name: 
            {
              given: [item.firstName],
              family: item.lastName,
  
            },
          relationship: [{
            coding: [
              {
                code: item.relationship
              }
            ]
          }],
          telecom: item.telecom,
        })

        if (item.id) {
          body.contact[index].id = item.id;
        }
      });

      await updateContact({id: uuid, body});
      formik.setSubmitting(false);
    },
  });

  const handleAddContact = () => {
    formik.setFieldValue('contact', [
      ...formik.values.contact,
      {
        firstName: '',
        lastName: '',
        telecom: [],
        relationship: '',
      },
    ]);
  }

  const handleRemoveContact = (index) => {
    formik.setFieldValue('contact', formik.values.contact.filter((_, i) => i !== index));
  }

  const handleUpdateContact = (index, values) => {
    formik.setFieldValue('contact', formik.values.contact.map((contact, i) => {
      if (i === index) {
        return values;
      }
      return contact;
    }));
  }

  if (isLoading || !data?.contact) {
    return null;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
        <Typography variant="h5">
          {t('Contacts')}
        </Typography>
        <IconButton size="small" sx={{ ml: 1 }} onClick={() => handleAddContact()}>
          <Add />
        </IconButton>
      </Box>
        <Box sx={{ mt: 2 }}>
          {formik.values.contact.map((contact, index) => (
            <ContactForm 
              key={index}
              contact={contact}
              updateContact={handleUpdateContact}
              removeContact={handleRemoveContact}
              index={index} 
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1}}>
          <Button
            variant="contained"
            onClick={() => formik.resetForm()}
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default ContactTab;
