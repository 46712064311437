import {
  Accordion,
  DialogActions,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  DialogContent,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ActiveIngredient, Medication, MedicationHistoryType } from '../../../../types';
import { FormTextField } from '../../../form-inputs';
import Row from '../../../Row';
import Timing from './Timing';
import { formikToApi, formikToText, getStatus, maxDosePeriodType, medicationFormType, APIToFormik } from './utils';
import { ExpandMore } from '@mui/icons-material';
import { greyboxApiActions } from '../../../../redux/api';
import { LoadingButton } from '@mui/lab';
import MaxDosePerPeriod from './MaxDosePerPeriod';

type MedicationDetailsProps = {
  searchedMed: Medication | null;
  editSelection: MedicationHistoryType | null;
  handleQuit: () => void;
};

type Ingredient = {
  name: string;
  dosageLabel: string;
  dosageNumber: string;
  dosageUnit: string;
};

const parseIngredients = (ingredients: ActiveIngredient[]) => {
  const parsedIngredients: Record<string, Ingredient> = {};

  ingredients.forEach((ingredient) => {
    parsedIngredients[ingredient.active_ingredient_code] = {
      name: ingredient.ingredient,
      dosageLabel: `${ingredient.strength} ${ingredient.strength_unit} `,
      dosageNumber: ingredient.strength,
      dosageUnit: ingredient.strength_unit,
    };

    if (ingredient.dosage_value && Number(ingredient.dosage_value)) {
      parsedIngredients[ingredient.active_ingredient_code].dosageLabel +=
        `/ ${ingredient.dosage_value} ${ingredient.dosage_unit} `;
      parsedIngredients[ingredient.active_ingredient_code].dosageNumber = ingredient.dosage_value;
      parsedIngredients[ingredient.active_ingredient_code].dosageUnit = ingredient.dosage_unit;
    }
  });

  return parsedIngredients;
};

const parseTotalDosage = (ingredients: Ingredient[]) => {
  const totalDosage = {
    value: 0,
    unit: ingredients[0].dosageUnit,
  };

  ingredients.forEach((ingredient) => {
    totalDosage.value += Number(ingredient.dosageNumber);
  });

  return totalDosage;
};

const MedicationDetails = ({ searchedMed, handleQuit, editSelection }: MedicationDetailsProps) => {
  const { t } = useTranslation();
  const medInfo = searchedMed ? searchedMed : editSelection?.medication;
  const { medication } = greyboxApiActions;
  const { uuid } = useParams<{ uuid: string }>();
  const ingredients = parseIngredients(medInfo.active_ingredient);
  const initDosage = parseTotalDosage(Object.values(ingredients));
  const [preview, setPreview] = React.useState('');
  const [postMedication, result] = medication.add();
  const [updateMedication] = medication.update();

  const initialValues = editSelection ? APIToFormik(editSelection) : 
  {
    timing: {
      code: 'BID',
      frequency: 2,
      period: 1,
      periodUnit: 'd',
    },
    maxDosePeriod: {
      maxDose: null,
      maxDoseUnit: null,
      period: null,
      periodUnit: null,
    },
    doseValue: initDosage.value,
    doseUnit: initDosage.unit,
    isMaxTolerated:  false,
    text: '',
    startDate: new Date().toISOString().split('T')[0],
    endDate: null,
    asNeeded: false,
    site: null,
    route: null,
    method: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      doseValue: yup.number().required().positive(),
      doseUnit: yup.string().required(),
      text: yup.string(),
      site: yup.string().nullable(),
      route: yup.string().nullable(),
      method: yup.string().nullable(),
      asNeeded: yup.boolean(),
      isMaxTolerated: yup.boolean(),
    }),
    onSubmit: async (values: medicationFormType) => {
      const dosage = formikToApi(values);
        const body = {
          start_date: values.startDate,
          end_date: values.endDate,
          account: uuid,
          status: getStatus(values.startDate, values.endDate),
          dosage_fhir: [dosage],
        } as Partial <MedicationHistoryType>;

        if (editSelection) {
          await updateMedication({ body: { status: 'entered-in-error' }, id: editSelection.uuid });
        }

        body['medication'] = medInfo.uuid;
        await postMedication({ body });

        formik.setSubmitting(false);
        handleQuit();
    },
  });

  useEffect(() => {
    setPreview(formikToText(formik.values));
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }} dividers >
        <Typography variant="h6" fontWeight={600}>
          {medInfo.brand_name}
        </Typography>
        <Box sx={{ mb: 2 }}>
          {Object.entries(ingredients).map(([key, val]) => (
            <Typography variant="caption" display="block" key={key}>
              {val.name}
              {' - '}
              {val.dosageLabel}
            </Typography>
          ))}
          {medInfo.active_ingredient.length > 1 && (
            <Typography variant="caption">{`${t('Total')} ${initDosage.value} ${initDosage.unit}`}</Typography>
          )}
        </Box>
        <Row>
          <FormTextField formik={formik} required name="doseValue" label={t('Dose')} type="number" />
          <FormTextField formik={formik} required name="doseUnit" label={t('Unit')} />
        </Row>
        <Row>
          <FormTextField formik={formik} required name="startDate" label={t('Start Date')} type="date" />
          <FormTextField formik={formik} name="endDate" label={t('End Date')} type="date" />
        </Row>
        <Row>
          <FormControlLabel
            label={t('As needed')}
            sx={{ ml: 0.5 }}
            name="asNeeded"
            onChange={formik.handleChange}
            control={<Checkbox checked={formik.values.asNeeded} />}
          />
          <FormControlLabel
            sx={{ ml: 0.5 }}
            label={t('Max Tolerated')}
            name="isMaxTolerated"
            onChange={formik.handleChange}
            control={<Checkbox />}
          />
        </Row>
        <Timing formik={formik} />
        <MaxDosePerPeriod onChange={(maxDosePeriod: maxDosePeriodType) => formik.setFieldValue('maxDosePeriod', maxDosePeriod)} />
        <Accordion sx={{ mt: 1 }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{t('Advanced')}</Typography>
          </AccordionSummary>
          <Row>
            <FormTextField
              formik={formik}
              name="site"
              label={t('Site')}
              tooltipText={t('Body site to administer to')}
            />
            <FormTextField
              formik={formik}
              name="route"
              label={t('Route')}
              tooltipText={t('How drug should enter body')}
            />
            <FormTextField
              formik={formik}
              name="method"
              label={t('Method')}
              tooltipText={t('Technique for administering medication')}
            />
          </Row>
          <Box sx={{ px: 1 }}>
            <TextField
              fullWidth
              minRows={2}
              onChange={formik.handleChange}
              name="text"
              label={t('Aditional Instructions')}
              multiline
            />
          </Box>
        </Accordion>
        <TextField
          inputProps={{ readOnly: true, style: { fontFamily: 'monospace' } }}
          fullWidth
          sx={{ mt: 2 }}
          value={preview}
          label={t('Preview')}
          multiline
          minRows={3}
        >
          {preview.toUpperCase()}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleQuit}>{t('Cancel')}</Button>
        <LoadingButton type="submit" variant="contained" color="primary" loading={formik.isSubmitting}>
          {t('Submit')}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default MedicationDetails;
