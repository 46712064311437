import React from 'react';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import QuestionIcon from '@mui/icons-material/HelpOutline';

interface PropsType {
  formik: any;
  name: string;
  label: string;
  tooltipText?: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  pattern?: string;
  min?: number;
  max?: number;
  sx?: any;
}

const FormTextField: React.FC<PropsType> = ({
  formik,
  name,
  label,
  required = false,
  disabled = false,
  tooltipText,
  type = 'text',
  pattern,
  min,
  max,
  sx = { mx: 1 },
}) => {
  const error = Boolean(formik.touched[name] && formik.errors[name]);
  const helperText = formik.touched[name] && formik.errors[name];
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  return (
    <TextField
      id={name}
      data-cy={name}
      sx={sx}
      name={name}
      label={required ? `${label} *` : label}
      placeholder={label}
      disabled={disabled}
      type={type}
      value={formik.values[name] || ''}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={error}
      helperText={helperText}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputProps: {
          pattern: pattern || undefined,
          min: min !== undefined ? min : undefined,
          max: max !== undefined ? max : undefined,
          'aria-describedby': ariaDescribedBy,
        },
        endAdornment: (
          tooltipText && (
            <InputAdornment position="end" sx={{ cursor: 'help' }}>
              <Tooltip title={tooltipText} arrow>
                <QuestionIcon />
              </Tooltip>
            </InputAdornment>
          )
        ),
      }}
      FormHelperTextProps={{ id: ariaDescribedBy }}
    />
  );
};

export default FormTextField;
