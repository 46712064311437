import { DosageFhir, Timing, Repeat, MedicationHistoryType } from "../../../../types";
import i18n from "../../../../i18n";

export type maxDosePeriodType = {
  period: number | null;
  periodUnit: string | null;
  maxDose: number | null; 
  maxDoseUnit: string | null;
};

export type timingFormType = {
  id?: string | null;
  code: string;
  frequency: number;
  frequencyMax?: number;
  period: number;
  periodUnit: string;
  when?: string;
  offset?: number | null;
  duration?: number | null;
  durationUnit?: string | null;
  dayOfWeek?: string[];
  timeOfDay?: string | null;
  count?: number | null;
};

export type medicationFormType = {
  text: string;
  asNeeded: boolean;
  maxDosePeriod: maxDosePeriodType;
  doseValue: number;
  doseUnit: string;
  site: string | null;
  route: string | null;
  method: string | null;
  timing: timingFormType;
  startDate: string;
  endDate: string | null;
  isMaxTolerated: boolean;
};


export const periodOptions: Record<string, string> = {
  'h':  i18n.t('Hour'),
  'd': i18n.t('Day'),
  'wk': i18n.t('Week'),
  'mo': i18n.t('Month'),
};

export const whenOptions: Record<string, string> = {
  'HS': i18n.t('Before sleep'),
  'WAKE': i18n.t('After waking up'),
  'C': i18n.t('With meal'),
  'AC': i18n.t('Before meal'),
  'PC': i18n.t('After meal'),
  'AM': i18n.t('In the morning'),
  'PM': i18n.t('In the evening'),
};

const timingToText = (timing: timingFormType) => {
  const { period, periodUnit, frequency, frequencyMax, when, offset } = timing;
  let text = '';

  if (frequency > 1 || frequencyMax) {
    text += `${frequency} `;
    if (frequencyMax) {
      text += `-${frequencyMax} `;
    }
    text += i18n.t('times') + ' ';
  } else {
    text += i18n.t('once') + ' ';
  }

  if (period === 1) {
    text += `${i18n.t('every')} ${periodOptions[periodUnit]} `;
  } else{
    text += `${i18n.t('every')} ${period} ${periodOptions[periodUnit]} `;
  }


  if (when) {
    if (offset) {
      text += `${offset} min. `;
    }
    
    text += (whenOptions[when]).toLowerCase();
  }
  
  return text;
}


export const formikToText = (values: medicationFormType) => {
  const { doseValue, doseUnit, text, asNeeded, timing, maxDosePeriod } = values;
  let preview = `${doseValue || ''} ${doseUnit}`

  if (timing) {
    preview += ` ${timingToText(timing)}`;
  }

  if (asNeeded) {
    preview += ' ' + i18n.t('as needed');
  }

  if (maxDosePeriod.maxDose) {
    preview += `\n${i18n.t('Max dose')}: ${maxDosePeriod.maxDose}`
  }

  if (maxDosePeriod.maxDoseUnit) {
    preview += ` ${maxDosePeriod.maxDoseUnit}`;
  }

  if (maxDosePeriod.period) {
    preview += ` ${i18n.t('per')} ${maxDosePeriod.period}`;
  }

  if (maxDosePeriod.periodUnit) {
    preview += ` ${periodOptions[maxDosePeriod.periodUnit]}`;
  }

  if (text) {
    preview += `\n${text}`;
  }

  return preview;
}

export const handlePreset = (preset: string , formik: any) => {
  switch (preset) {
    case 'BID':
      formik.setValues({
        ...formik.values,
        code: 'BID',
        frequency: 2,
        period: 1,
        periodUnit: 'd',
      });
      break;
    case 'TID':
      formik.setValues({
        ...formik.values,
        code: 'TID',
        frequency: 3,
        period: 1,
        periodUnit: 'd',
      });
      break;
    case 'QID':
      formik.setValues({
        ...formik.values,
        code: 'QID',
        frequency: 4,
        period: 1,
        periodUnit: 'd',
      });
      break;
    case 'AM':
      formik.setValues({
        ...formik.values,
        code: 'AM',
        frequency: 1,
        period: 1,
        periodUnit: 'd',
        when: 'WAKE',
      });
      break;
    case 'PM':
      formik.setValues({
        ...formik.values,
        code: 'PM',
        frequency: 1,
        period: 1,
        periodUnit: 'd',
        when: 'PCD',
      });
      break;
    case 'QD':
      formik.setValues({
        ...formik.values,
        code: 'QD',
        frequency: 1,
        period: 1,
        periodUnit: 'd',
      });
      break;
    case 'QOD':
      formik.setValues({
        ...formik.values,
        code: 'QOD',
        frequency: 1,
        period: 2,
        periodUnit: 'd',
      });
      break;
    case 'Q1H':
      formik.setValues({
        ...formik.values,
        code: 'Q1H',
        frequency: 1,
        period: 1,
        periodUnit: 'h',
      });
      break;
    case 'Q2H':
      formik.setValues({
        ...formik.values,
        code: 'Q2H',
        frequency: 1,
        period: 2,
        periodUnit: 'h',
      });
      break;
    case 'Q3H':
      formik.setValues({
        ...formik.values,
        code: 'Q3H',
        frequency: 1,
        period: 3,
        periodUnit: 'h',
      });
      break;
    case 'Q4H':
      formik.setValues({
        ...formik.values,
        code: 'Q4H',
        frequency: 1,
        period: 4,
        periodUnit: 'h',
      });
      break;
    case 'Q6H':
      formik.setValues({
        ...formik.values,
        code: 'Q6H',
        frequency: 1,
        period: 6,
        periodUnit: 'h',
      });
      break;
    case 'Q8H':
      formik.setValues({
        ...formik.values,
        code: 'Q8H',
        frequency: 1,
        period: 8,
        periodUnit: 'h',
      });
      break;
    case 'BED':
      formik.setValues({
        ...formik.values,
        code: 'BED',
        frequency: 1,
        period: 1,
        periodUnit: 'd',
        when: 'HS',
      });
      break;
    case 'WK':
      formik.setValues({
        ...formik.values,
        code: 'WK',
        frequency: 1,
        period: 1,
        periodUnit: 'wk',
      });
      break;
    case 'MO':
      formik.setValues({
        ...formik.values,
        code: 'MO',
        frequency: 1,
        period: 1,
        periodUnit: 'mo',
      });
      break;
    case 'CUSTOM':
      formik.setValues({
        ...formik.values,
        code: 'CUSTOM',
      });
      break;
    default:
      break;
  }
};

export const timingCode = [
  'BID',
  'TID',
  'QID',
  'AM',
  'PM',
  'QD',
  'QOD',
  'Q1H',
  'Q2H',
  'Q3H',
  'Q4H',
  'Q6H',
  'Q8H',
  'BED',
  'WK',
  'MO',
  'CUSTOM',
];

export const formikToApi = (values: medicationFormType) => {
  const body: DosageFhir = {};
  const { timing, doseValue, doseUnit, maxDosePeriod, ...rest } = values;
  console.log(values)

  body['doseAndRate'] = [{
    doseQuantity: {
      value: doseValue,
      unit: doseUnit,
    },
  }];

  const timingObject: Timing = {}
  const repeat: Repeat = {};

  if (maxDosePeriod.maxDose && maxDosePeriod.period && 
      maxDosePeriod.maxDoseUnit && maxDosePeriod.periodUnit) {
    body.maxDosePerPeriod = [{
      numerator: {
        value: maxDosePeriod.maxDose,
        unit: maxDosePeriod.maxDoseUnit,
      },
      denominator: {
        value: maxDosePeriod.period,
        unit: maxDosePeriod.periodUnit,
      },
    }];
  }

  if (timing.id) {
    timingObject.id = timing.id;
  }

  for (const key in timing) {
    if (key === 'code') {
      timingObject['code'] = { coding: [{ code: timing[key] }] }
      continue;
    } else if (key === 'dayOfWeek') {
      if (timing.dayOfWeek && timing.dayOfWeek.length > 0) {
        repeat[key] = timing[key];
      }
      continue;
    } else if (timing[key as keyof timingFormType]) {
      repeat[key as keyof Repeat] = timing[key as keyof timingFormType] as any;
    }
  }

      timingObject['repeat'] = repeat;
      body['timing'] = timingObject;

      for (const key in rest) {
        if (key === 'site' || key === 'route' || key === 'method') {
          if (rest[key] !== null) {
            body[key] = { coding: [{ code: rest[key]! }]}
          }
        } else if (rest[key as keyof typeof rest] !== null) {
            body[key as keyof DosageFhir] = rest[key as keyof typeof rest] as any;
        }
    }

  return body;
}


export const getStatus = (startDate: string, endDate: string | null) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;

  if ( start > now) {
    return 'proposed';
  } else if (end && end < now) {
    return 'completed';
  } else {
    return 'active';
  }
}

export const APIToFormik = (medication: MedicationHistoryType) => {
  if (!medication.dosage_fhir || medication.dosage_fhir.length === 0) {
    return null;
  }
  
  const dosage = medication.dosage_fhir[0];

  const timing: timingFormType = {
    id: dosage.timing?.id || null,
    code: dosage.timing?.code?.coding[0].code || 'CUSTOM',
    frequency: dosage.timing?.repeat?.frequency || 1,
    frequencyMax: dosage.timing?.repeat?.frequencyMax || 0,
    period: dosage.timing?.repeat?.period || 1,
    periodUnit: dosage.timing?.repeat?.periodUnit || 'd',
    when: dosage.timing?.repeat?.when,
    offset: dosage.timing?.repeat?.offset || null,
    duration: dosage.timing?.repeat?.duration || null,
    durationUnit: dosage.timing?.repeat?.durationUnit || null,
    dayOfWeek: dosage.timing?.repeat?.dayOfWeek || [],
    timeOfDay: dosage.timing?.repeat?.timeOfDay || null,
    count: dosage.timing?.repeat?.count || null,
  };

  const maxDosePeriod: maxDosePeriodType = {
    period: dosage.maxDosePerPeriod?.[0].denominator.value || null,
    periodUnit: dosage.maxDosePerPeriod?.[0].denominator.unit || null,
    maxDose: dosage.maxDosePerPeriod?.[0].numerator.value || null,
    maxDoseUnit: dosage.maxDosePerPeriod?.[0].numerator.unit || null,
  };

  const formikValues: medicationFormType = {
    id: dosage.id || null,
    text: dosage.text || '',
    asNeeded: dosage.asNeeded || false,
    doseValue: dosage.doseAndRate?.[0].doseQuantity.value!,
    doseUnit: dosage.doseAndRate?.[0].doseQuantity.unit!,
    site: dosage.site?.coding?.[0].code || null,
    route: dosage.route?.coding?.[0].code || null,
    method: dosage.method?.coding?.[0].code || null,
    timing,
    maxDosePeriod,
    startDate: medication.start_date,
    endDate: medication.end_date || null,
    isMaxTolerated: medication.max_tolerated_dosage,
  };

  return formikValues;
}