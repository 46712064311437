import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box, Button, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import FormTextField from '../form-inputs/FormTextField';
import MultiSelect from '../form-inputs/MultiSelect';
import PhoneNumber from '../form-inputs/PhoneNumber';
import Language from '../form-inputs/Language';
import {
  Thresholds, thresholdValidations, parseThresholdsForPost, thresholdsInitValue,
} from '../form-inputs/Thresholds';
import Row from '../Row';

const createValidation = (enabledVitals, t) => {
  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('Required')),
    lastName: yup.string().required(t('Required')),
    email: yup.string().email(t('Invalid email')),
    phoneNumber: yup.string(),
    language: yup.string().nullable().required(t('Required')),
    staffs: yup.array().of(yup.object().shape({
      id: yup.string(), label: yup.string().required(t('Required')),
    })).required(t('Required')),
    primaryDiagnosis: yup.array().of(yup.object()),
    secondaryDiagnosis: yup.array().of(yup.object()),
  });

  validationSchema.concat(thresholdValidations(enabledVitals));

  return validationSchema;
};

const parseFormDataForPost = (values, vitalsConfig) => {
  const {
    firstName, lastName, email, phoneNumber, language, staffs,
    primaryDiagnosis, secondaryDiagnosis, ...rest
  } = values;

  const thresholds = parseThresholdsForPost(rest, vitalsConfig);

  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    language,
    staffs: staffs.map((staff) => staff.id),
    diagnosis: primaryDiagnosis?.map((diag) => diag.key),
    secondary_diagnosis: secondaryDiagnosis?.map((diag) => diag.id),
    invitationFormFields: {
      firstName,
      lastName,
      email,
      phoneNumber,
      language,
      staffs: staffs.map((staff) => staff.id),
      diagnosis: primaryDiagnosis.map((diag) => diag.key),
      secondary_diagnosis: secondaryDiagnosis.map((diag) => diag.id),
    },
    Threshold: thresholds,
  };
};

const initValue = (vitalsConfig, user, defaultThresholds) => {
  const values = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    language: 'fr',
    primaryDiagnosis: [],
    secondaryDiagnosis: [],
    staffs: user ? [{ label: `${user.firstName} ${user.lastName}`, id: user.uuid || '' }] : [],
  };

  const thresholds = thresholdsInitValue(vitalsConfig, defaultThresholds);
  Object.assign(values, thresholds);

  return values;
};

/**
 * Form to invite a new patient.
 */
const PatientInvitation = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { account, invitation } = greyboxApiActions;
  const { data = [], isLoading } = account.list({ self: true });
  const { vitalsConfig, clinic, thresholds } = useSelector((state) => state.clinic);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [sendInvitation] = invitation.add();
  const configs = clinic.config.features_enable;

  const formik = useFormik({
    initialValues: initValue(vitalsConfig, data[0], thresholds),
    validationSchema: createValidation(Object.keys(vitalsConfig), t),
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      if (values.email === '' && values.phoneNumber === '') {
        return {
          email: t('Must provide either email or phone number'),
          phoneNumber: t('Must provide either email or phone number'),
        };
      }
      return null;
    },
    onSubmit: (values) => {
      const body = parseFormDataForPost(values, vitalsConfig);

      if (body.email === '') {
        delete body.email;
      }

      if (body.phoneNumber === '' || body.phoneNumber.length < 7) {
        delete body.phoneNumber;
      }

      body.clinic = clinic.id;
      sendInvitation({ body, feedback: { success: t('Invitation sent') } }).unwrap().then(() => {
        formik.setSubmitting(false);
        formik.resetForm();
        handleClose();
      }).catch((error) => {
        formik.setSubmitting(false);
        // Assuming the server returns error details in a predictable format
        if (error.status === 400 && error.data) {
          const errors = error.data;
          formik.setErrors(errors);
        }
      });
    },
  });

  const handleNextStep = async () => {
    const isValid = await formik.validateForm();
    if (Object.keys(isValid).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      formik.setTouched(isValid, false);
      formik.validateForm();
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  if (isLoading) return null;

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {t('Register a new patient')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {currentStep === 1 && (
            <>
              <Row>
                <FormTextField formik={formik} name="firstName" label={t('First name')} required />
                <FormTextField formik={formik} name="lastName" label={t('Last name')} required />
              </Row>
              <Row>
                <FormTextField 
                  formik={formik}
                  required={sendInvitation}
                  name="email"
                  label={t('Email')}
                />
                <PhoneNumber formik={formik} name="phoneNumber" />
              </Row>
              <Row>
                <Language formik={formik} />
              </Row>
              <MultiSelect
                formik={formik}
                type="diagnosis"
                level="primary"
                label={t('Primary Diagnosis')}
                name="primaryDiagnosis"
              />
              {configs.secondary_diagnosis && (
                <MultiSelect
                  formik={formik}
                  type="diagnosis"
                  level="secondary"
                  name="secondaryDiagnosis"
                  label={t('Secondary Diagnosis')}
                />
              )}
              <MultiSelect formik={formik} type="staffs" label={t('Assigned HCP')} name="staffs" />
            </>
          )}
          {currentStep === 2 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">{t('Vital thresholds adjustments')}</Typography>
              <Thresholds formik={formik} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {currentStep > 1 && (
            <Button onClick={handlePrevStep} variant="outlined">
              {t('Back')}
            </Button>
          )}
          {currentStep === 1 && (
            <Button onClick={handleClose} variant="outlined">
              {t('Cancel')}
            </Button>
          )}
          {currentStep === 1 && (
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ ml: 'auto' }}
              loading={formik.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          )}
          {currentStep === 1 && (
            <Button onClick={handleNextStep} variant="contained">
              {t('Next')}
            </Button>
          )}
          {currentStep === 2 && (
            <Button onClick={handleClose} variant="outlined">
              {t('Cancel')}
            </Button>
          )}
          {currentStep === 2 && (
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ ml: 'auto' }}
              loading={formik.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PatientInvitation;
