
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import TrashIcon from '@mui/icons-material/Delete';
import Row from '../../Row';
import {
  FormTextField
} from '../../form-inputs';
import Telecom from '../Telecom';

const ContactForm = ({ contact, updateContact, index, removeContact }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const informations = useFormik({
    initialValues: {
      firstName: contact.firstName || '',
      lastName: contact.lastName || '',
      telecom: contact.telecom || [],
      relationship: contact.relationship || '',
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateContact(index, informations.values);
  }, [informations.values]);

  return (
    <form>
      <Box sx={{
        p: 1,
        border: `1px solid ${theme.palette.divider}`,
        mb: 1,
        borderRadius: '5px',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => removeContact(index)}>
            <TrashIcon />
          </IconButton>
        </Box>
        <Row>
          <FormTextField formik={informations} name="firstName" required label={t('First Name')} />
          <FormTextField formik={informations} name="lastName" required label={t('Last Name')} />
        </Row>
        <Row>
          <FormTextField formik={informations} name="relationship" required label={t('Relationship')} />
        </Row>
        <Telecom informations={informations} />
      </Box>
    </form>
  );
};

export default ContactForm;
