import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import FormSelect from './FormSelect';
import { languages } from '../../helpers/languagesConfig';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const Language = (props: PropsType) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      {...props}
      name="language"
      label={t('Language')}
      sx={{ ...props.sx}}  // Ensure full width is applied
    >
      {languages.map((language) => (
        <MenuItem key={language.code} value={language.code}>
          {t(language.labelKey)}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

Language.defaultProps = {
  required: false,
  sx: { mx: 1, minWidth: 120, fullWidth: true },  // Apply full width by default
};

export default Language;
