import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Paper, Stack, Table, IconButton,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Menu, MenuItem,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { greyboxApiActions } from '../../redux/api';
import { RootState } from '../../redux/store';
import TableSkeleton from '../skeletons/TableSkeleton';
import CareTeamDialog from './CareTeamDialog';
import { Coding } from './interfaces';
import { CARE_TEAM_CATEGORY } from './utils';
import CareTeamInvitation from '../invitations/CareTeamInvitation';

const CareTeam = () => {
  const { uuid } = useParams();
  const hasPatient = Boolean(uuid);
  const { t } = useTranslation();
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const [open, setOpen] = React.useState(false);
  const { careTeam } = greyboxApiActions;
  const navigate = useNavigate();
  const { data, isFetching } = careTeam.list({
    clinic: clinic!.id,
    'status:in': 'active,proposed,inactive',
    'managing-organization:Organization.identifier': clinic!.id,
    [hasPatient ? 'subject' : 'subject:missing']: hasPatient ? `Patient/${uuid}` : 'true',
  });
  const [selectedCareTeam, setSelectedCareTeam] = React.useState<any>(null);
  const [invitationOpen, setInvitationOpen] = React.useState(null);
  const [hovering, setIsHovering] = React.useState(null);
  const [updateCareTeam] = careTeam.update();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const setInviteOpen = (id) => {
    setOpen(false);
    setInvitationOpen(id);
  };

  const col = [
    {
      id: 'name',
      label: t('Name'),
    },
    {
      id: 'category',
      label: t('Category'),
    },
    {
      id: 'status',
      label: t('Status'),
    },
    {
      id: 'meta',
      label: t('Last Updated'),

    },
  ];

  const handleDelete = (event, id) => {
    event.stopPropagation();
    updateCareTeam({ id: id, body: { status: 'entered-in-error' } });
  };

  const handleEdit = (id) => {
    if (hasPatient) {
      navigate(`/patient-profile/${uuid}/care-team/${id}`);
    } else {
      navigate(`/clinic-settings/care-team/${id}`);
    }
  };

  if (isFetching) {
    return (
      <Paper sx={{ p: 2, width: '100%', height: 'fit-content' }}>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <TableSkeleton columns={col} rowCount={6} />
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Box sx={{ width: '100%', height: 'fit-content' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Care Teams')}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mb: 1 }}
      >
        <Button
          data-cy="add-care-team"
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          {t('Add')}
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {col.map((item) => (
                <TableCell key={item.id}>{item.label}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.entry.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                  }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {t('No care team found')}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {data.entry.map((item, index) => (
              <TableRow
                key={item.resource.id}
                sx={{ height: 68 }}
                hover
                data-cy={`care-team-${index}`}
                onMouseEnter={() => setIsHovering(item.resource.id)}
                onMouseLeave={() => setIsHovering(null)}
              >
                <TableCell>{item.resource.name}</TableCell>
                <TableCell>
                  {item.resource.category
                    ?.map((cat: Coding) => CARE_TEAM_CATEGORY[cat.coding[0].code])
                    .join(', ')}
                </TableCell>
                <TableCell>{t(item.resource.status)}</TableCell>
                <TableCell>{moment(item.resource.meta.lastUpdated).format('YYYY-MM-DD')}</TableCell>
                <TableCell align="right" sx={{ width: 50 }}>
                  {hovering === item.resource.id && (
                    <div>
                      <IconButton
                        size="small"
                        data-cy={`care-team-menu-${index}`}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        sx={{ transition: 'all 0.7s ease-in-out' }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem onClick={() => handleEdit(item.resource.id)}>
                          {t('Edit')}
                        </MenuItem>
                        <MenuItem
                          sx={{ color: (theme) => theme.palette.error.main }}
                          data-cy={`care-team-delete-${index}`}
                          onClick={(event) => handleDelete(event, item.resource.id)}
                        >
                          {t('Delete')}
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <CareTeamDialog
          open={open}
          handleClose={() => {
            setOpen(false);
            setSelectedCareTeam(null);
          }}
          selectedCareTeam={selectedCareTeam}
          hasPatient={hasPatient}
          setInviteOpen={setInviteOpen}
        />
      )}
      {Boolean(invitationOpen) && (
        <CareTeamInvitation
          open={Boolean(invitationOpen)}
          handleClose={() => setInvitationOpen(null)}
          hasPatient={hasPatient}
          careTeamId={invitationOpen}
        />
      )}
    </Box>
  );
};

export default CareTeam;
